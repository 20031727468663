window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

const cookieSettings = getCookieSettings('eu-cookies');
if (cookieSettings === null) {
    gtag('consent', 'default', {
        'functionality_storage': 'granted',
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'analytics_storage': 'denied',
        'personalization_storage': 'denied'
    });
} else {
    gtag('consent', 'default', {
        'functionality_storage': 'granted',
        'ad_storage': cookieSettings.marketing ? 'granted' : 'denied',
        'ad_user_data': cookieSettings.marketing ? 'granted' : 'denied',
        'analytics_storage': cookieSettings.analytics ? 'granted' : 'denied',
        'personalization_storage': cookieSettings.analytics ? 'granted' : 'denied',
    });
}

gtag('js', new Date());
gtag('config', 'AW-969478119');

document.addEventListener('DOMContentLoaded', function() {
    const cookieBanner = document.querySelector('#eu-cookies');

    const acceptButton = cookieBanner.querySelector('button[data-accept]');
    const denyButton = cookieBanner.querySelector('button[data-deny]');
    const saveButton = cookieBanner.querySelector('button[data-save]');

    const changeButton = document.querySelector('#change-cookie-settings');

    const marketingCheckbox = cookieBanner.querySelector('#eu-cookies__settings--marketing');
    // const userDataCheckbox = cookieBanner.querySelector('#eu-cookies__settings--user_data');
    const analyticsCheckbox = cookieBanner.querySelector('#eu-cookies__settings--analytics');
    // const personalizationCheckbox = cookieBanner.querySelector('#eu-cookies__settings--personalization');

    if (marketingCheckbox) {
        marketingCheckbox.checked = cookieSettings === null ? false : cookieSettings.marketing;
    }
    // if (userDataCheckbox) {
    //     userDataCheckbox.checked = cookieSettings === null ? false : cookieSettings.user_data;
    // }
    if (analyticsCheckbox) {
        analyticsCheckbox.checked = cookieSettings === null ? false : cookieSettings.analytics;
    }
    // if (personalizationCheckbox) {
    //     personalizationCheckbox.checked = cookieSettings === null ? false : cookieSettings.personalization;
    // }

    if (cookieSettings === null) {
        cookieBanner.style.display = 'block';
    }

    acceptButton.addEventListener('click', () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 10);

        saveCookieSettings(true);

        cookieBanner.style.display = 'none';
    });

    denyButton.addEventListener('click', () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 10);

        saveCookieSettings(false);

        cookieBanner.style.display = 'none';
    });

    saveButton.addEventListener('click', () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 10);

        saveCookieSettings(null);

        cookieBanner.style.display = 'none';
    });

    changeButton.addEventListener('click', (e) => {
        e.preventDefault();

        cookieBanner.style.display = 'block';
    });
});

function getCookieSettings() {
    const name = 'eu-cookies';

    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        const data = parts.pop().split(';').shift();
        if (data === '' || data === '1' || data === '0') {
            return null;
        }

        return JSON.parse(data);
    }

    return null;
}

/**
 *
 * @param {bool|null} accept
 */
function saveCookieSettings(accept) {
    const name = 'eu-cookies';

    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    const marketingCheckbox = document.querySelector('#eu-cookies__settings--marketing');
    // const userDataCheckbox = document.querySelector('#eu-cookies__settings--user_data');
    const analyticsCheckbox = document.querySelector('#eu-cookies__settings--analytics');
    // const personalizationCheckbox = document.querySelector('#eu-cookies__settings--personalization');

    const marketing = marketingCheckbox ? marketingCheckbox.checked : false;
    // const userData = userDataCheckbox ? userDataCheckbox.checked : false;
    const analytics = analyticsCheckbox ? analyticsCheckbox.checked : false;
    // const personalization = personalizationCheckbox ? personalizationCheckbox.checked : false;

    const data = {
        marketing: accept === null ? marketing : accept,
        //user_data: accept === null ? userData : accept,
        analytics: accept === null ? analytics : accept,
        // personalization: accept === null ? personalization : accept,
    };

    gtag('consent', 'update', {
        'ad_storage': data.marketing ? 'granted' : 'denied',
        'ad_user_data': data.marketing ? 'granted' : 'denied',
        'analytics_storage': data.analytics ? 'granted' : 'denied',
        'personalization_storage': data.analytics ? 'granted' : 'denied'
    });

    document.cookie = name + '=' + JSON.stringify(data) + '; path=/; expires=' + date.toGMTString();
}